import i18n from '../i18n.js'
const DISTANCE_TYPE_QUESTIONS = ['distance', 'distance-road'];

/**
 * Creates a google map, adds markers and info popup.
 * @param id  		string  		The id of the div to use.
 * @param sites  	array  			An array of objects (sites) with at least lat/lng properties.
 * @param options  	object  		An options object to override any defaults.
 * @return Map 						A Google Maps Map instance.
 */
async function createMap(id, sites, options = {})
{
	const { Map } = await google.maps.importLibrary("maps");
	const { AdvancedMarkerElement, PinElement } = await google.maps.importLibrary("marker");

	// Merge our default options with any passed to us
	let config = $.extend(
		{
			mapId: '887d51c900cf37d',
			zoom: 13,
			zoomControl: true,
			mapTypeControl: true,
			scaleControl: false,
			streetViewControl: true,
			rotateControl: false,
			fullscreenControl: true,
			mapTypeControlOptions: {
				style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
				mapTypeIds: ["roadmap", "satellite"],
				position: google.maps.ControlPosition.TOP_LEFT,
			},
			zoomControlOptions: {
				position: google.maps.ControlPosition.LEFT_TOP,
			},
			streetViewControlOptions: {
				position: google.maps.ControlPosition.LEFT_TOP,
			},
			fullscreenControlOptions: {
				position: google.maps.ControlPosition.TOP_LEFT,
			}
		},
		options
	);

	// Instantiate some objects
	var bounds = new google.maps.LatLngBounds();
	var map = new Map(document.getElementById(id), config);
	map.zp = { id: id, markers : [] };
	var latLng;
	var cluster = options.cluster || false;
	var markers = [];

	// Loop through our sites
	$.each(sites, (i, site) => {
		// Do we have an indicator to tell us to use marker clustering?
		if (site.cluster) {
			cluster = true;
		}
		// Add the marker to our map
		createMarker(site, map);


	});

	// Either centre on our one point or fit the map to show all
	setMapBounds(map);

	if (cluster && typeof MarkerClusterer != 'undefined') {

		var clusterStyles = [
			MarkerClusterer.withDefaultStyle({
				width: 53,
				height: 52,
				url: '/assets/img/m1.png',
				textColor: "#ffffff",
				textSize: 11,
			}),
			MarkerClusterer.withDefaultStyle({
				width: 56,
				height: 55,
				url: '/assets/img/m2.png',
				textColor: "#ffffff",
				textSize: 11,
			}),
			MarkerClusterer.withDefaultStyle({
				width: 66,
				height: 65,
				url: '/assets/img/m3.png',
				textColor: "#ffffff",
				textSize: 12,
			}),
			MarkerClusterer.withDefaultStyle({
				width: 78,
				height: 77,
				url: '/assets/img/m4.png',
				textColor: "#ffffff",
				textSize: 12,
			}),
			MarkerClusterer.withDefaultStyle({
				width: 90,
				height: 89,
				url: '/assets/img/m5.png',
				textColor: "#ffffff",
				textSize: 12,
			})
		];

		var markerCluster = new MarkerClusterer(
			map,
			map.zp.markers,
			{ maxZoom: 8, styles: clusterStyles } // { imagePath: '/assets/img/m' });
		);
	}

	// Return the map object
	return map;
}

let defaultIconUrl = ''; // Variable to store the default icon URL

// Function to fetch the default icon from the backend
async function fetchDefaultIcon() {
	const response = await fetch('/applicant/grabDefaultIconAjax', {
		method: 'GET',
		headers: {
			'X-Requested-With': 'XMLHttpRequest'
		}
	});

	let defaultIcon = await response.text();
	defaultIconUrl = '/assets/img/map-marker-' + defaultIcon + '-highlight.png';
}

// Call the function to fetch the default icon once
fetchDefaultIcon();

/**
 * Creates a marker on a map.
 *
 * @param  object          site The site to place a marker for.
 * @param  google.maps.Map map The Google Maps object.
 * @return void
 */
function createMarker(site, map) {
	// Ensure we have a valid lat/lng, skip if we don't
	let lat = parseFloat(site.lat);
	let lng = parseFloat(site.lng);
	if (isNaN(lat) || isNaN(lng) || (lat == 0 && lng == 0)) {
		return true;
	}

	// Create our icon using the fetched default icon URL
	const icon = document.createElement('img');
	icon.src = defaultIconUrl; // Use the fetched default icon URL
	icon.alt = i18n.__('portal.accessibility.map.marker', { name: site.name });

	// Create our marker
	const latLng = new google.maps.LatLng(lat, lng);
	var marker = new google.maps.marker.AdvancedMarkerElement({
		title: site.title ? site.title : 'Location Pin',
		position: latLng,
		content: icon,
		map: map,
		gmpDraggable: site.draggable ? site.draggable : false,
	});

	Object.defineProperties(marker, {
		zp: {
			varvalue: {},
			writable: true,
		}
	});

	marker.zp = {
		site: site,
		getIcon: () => {
			return marker.content.src;
		},
		setIcon: (img) => {
			const icon = document.createElement('img');
			icon.src = img;
			icon.alt = i18n.__('portal.accessibility.map.marker', { name: site.name });
			marker.content = icon;
		}
	};

	// If we have content create an info popup
	if (site.content) {
		marker.zp.infoWindow = new google.maps.InfoWindow({
			ariaLabel: "Location Information Dialog Box",
		});
		marker.zp.infoWindow.setContent(site.content);
		marker.zp.infoWindow.setPosition(latLng);
		google.maps.event.addListener(marker, 'click', function () {
			this.zp.infoWindow.open(marker.map, this);
		});
	}

	// If the marker is draggable create a listener to fire an event on the map
	google.maps.event.addListener(marker, 'dragend', (function (site) {
		return function (e) {
			$('#' + map.zp.id).trigger('drop.marker', [site, e.latLng])
		};
	})(site));

	map.zp.markers.push(marker);
}

/**
 * Sets the bounds on a map uing the marker array stored in the map object.
 *
 * @param  google.maps.Map          map The Google Maps object.
 * @return google.maps.LatLngBounds     The Google Maps bounds object
 */
function setMapBounds(map) {
	if (map.zp.markers.length == 1) {
		map.setCenter(map.zp.markers[0].position);
	} else if (map.zp.markers.length == 0) {
		if ($('input[name="bounds"]').length) {
			let parts = $('input[name="bounds"]').val().split('|');
			if (parts.length == 2) {
				let sw = parts[0].split(',');
				let ne = parts[1].split(',');
				map.fitBounds(new google.maps.LatLngBounds(
					new google.maps.LatLng({ lat: Number(sw[0]), lng: Number(sw[1]) }),
					new google.maps.LatLng({ lat: Number(ne[0]), lng: Number(ne[1]) })
				));
			}
		}
	} else {
		var bounds = new google.maps.LatLngBounds();
		map.zp.markers.forEach(marker => {
			bounds.extend(marker.position)
		});
		map.fitBounds(bounds);
		map._bounds = bounds;
	}
	return bounds;
}

/**
 * Creates a small and large Google map for a site or group of sites.
 * @param integer 	locationId  	The record id.
 * @param string 	locationType  	The location type (groups or sites).
 */
async function createGoogleMap(locationId, locationType = 'sites') {
	$.ajax({
		url: '/maps/markers/' + locationType + '/' + locationId,
		type: 'GET',
		dataType: 'json'
	}).then((data) => {
		if (data.error == true) {
			logoutInactive(data);
		}

		/* DRAGGABLE TEST
		$.each(data, function(i, site) {
			site.draggable = true;
		});
		*/

		var smallMap, largeMap;

		createMap(
			'map-canvas',
			data,
			{
				mapTypeControl: false,
				streetViewControl: false,
				fullscreenControl: false,
			}
		).then(map => {
			smallMap = map;

			// Handle fitBounds issue on hidden maps
			smallMap.addListener('bounds_changed', () => {
				if (smallMap._bounds != undefined) {
					let height = $('#map-canvas').height();
					if (smallMap._height == undefined) {
						if (height > 0) {
							delete smallMap._bounds;
						}
					} else {
						if (smallMap._height == 0 && height > 0) {
							smallMap._height = height;
							smallMap.fitBounds(smallMap._bounds);
							delete smallMap._bounds;
						}
					}
					smallMap._height = height;
				}
			});
		}).then(map => {
			createMap(
				'map-canvas-large',
				data
			).then(map => {
				largeMap = map;
			})
			.then(() => {
				// Capture zoom changes on the small map to keep large map at the same value
				const zoom = () => {
					let zoom = smallMap.getZoom();
					if (zoom > 0) {
						largeMap.setZoom(zoom);
					}
				};
				smallMap.addListener('zoom_changed', zoom)
				smallMap.addListener('idle', zoom);

				$('body').trigger('googlemap.ready', [smallMap, largeMap]);
			});
		});
	});
}

// Pulls in questionaire results for this permit from the DB
function getQuestionaireResults(permit) {
	return $.ajax({
		url: '/permits/getQuestionaireData',
		type: 'POST',
		dataType: 'JSON',
		data: { permitId: permit },
	});
}

// Initialises the map
function initMap(id, mapContent, questionType) {
	let map = new google.maps.Map(document.getElementById(id), {});

	let pin = null;
	let markers = [];
	let coords = [];

	$.each(mapContent, function(index, val) {
		pin = { url: "//maps.google.com/mapfiles/ms/icons/red-dot.png" };
		if (index == 'site') {
			map.setCenter({ lat: parseFloat(val['lat']), lng: parseFloat(val['lng']) });
			pin = { url: "//maps.google.com/mapfiles/ms/icons/blue-dot.png" };
		}

		if (index != 'warn') {
			markers.push(addMarker(map, parseFloat(val['lat']), parseFloat(val['lng']), pin, val['address']));
			coords.push({ lat: parseFloat(val['lat']), lng: parseFloat(val['lng']) });
		}
	});

	if (questionType == 'distance') {
		addLine(map, coords);
	} else {
		showRoute(map, coords);
	}

	let bounds = new google.maps.LatLngBounds();
	for (let i = 0; i < markers.length; i++) {
		bounds.extend(markers[i].position);
	}

	map.fitBounds(bounds);
}

// Adds the route to the map
function showRoute(map, coordinates) {
	let directionsService = new google.maps.DirectionsService();
	let directionsDisplay = new google.maps.DirectionsRenderer({ suppressMarkers: true });

	let start = new google.maps.LatLng(coordinates[0]['lat'], coordinates[0]['lng']);
	let end = new google.maps.LatLng(coordinates[1]['lat'], coordinates[1]['lng']);

	let request = {
		origin: start,
		destination: end,
		travelMode: google.maps.TravelMode.DRIVING
	};

	directionsService.route(request, function (response, status) {
		if (status == google.maps.DirectionsStatus.OK) {
			directionsDisplay.setDirections(response);
			directionsDisplay.setMap(map);
		}
	});
}

// Adds line to the map
function addLine(map, coordinates) {

	var path = new google.maps.Polyline({
		path: coordinates,
		geodesic: true,
		strokeColor: '#FF0000',
		strokeOpacity: 1.0,
		strokeWeight: 2
	});

	path.setMap(map);
}

// Adds marker to the map
function addMarker(map, lat, lng, pin, info) {
	let marker = new google.maps.Marker({
		position: { lat: lat, lng: lng },
		map: map,
		icon: pin,
		title: 'Location Pin',
	});

	let infowindow = new google.maps.InfoWindow({
		content: info,
		ariaLabel: "Location Information Dialog Box",
	});

	marker.addListener('click', function () {
		infowindow.open(map, marker);
	});

	return marker;
}

export { createMap, createGoogleMap, createMarker, setMapBounds }
